import axios from "axios";
import { baseUrl } from "../../global.js";
import { Message } from "element-ui"
import { getlocalStorage } from "@/utils/main.js";

const ajax = axios.create({
    baseURL: baseUrl
});
// Add a request interceptor
ajax.interceptors.request.use(function (config) {
  const token = getlocalStorage('token') // 注册页和登录页不加token
  if(config.url==="/api/user/register" || config.url==="/api/sso/account/login"){
      return config
  } else if (token) {
    //   获取token时间戳 对比是否过期
    // const ts = Math.round(new Date().getTime()/1000).toString()
    // const exp = token.exp>=ts
    // if (exp) {
        config.headers.Authorization = `Bearer ${getlocalStorage('token')}` 
    // }         
  }
    // Do something before request is sent
    // console.log('请求拦截');
    // console.log(JSON.stringify(config, null, 4));
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
// Add a response interceptor
ajax.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.code !== 200 && response.data.code !== 30004 && response.data.code !== 10007) {
        Message.error(response.data.message)
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default ajax;