const uzStorage = () => {
    if (process.browser) {
        const ls = window.localStorage;
        return ls;
    }
};
// 设置一个缓存
const setStorage = (key, value) => {
    let v = value;
    if (typeof v === "object") {
        v = JSON.stringify(v);
        v = `obj-${v}`;
    } else {
        v = `str-${v}`;
    }
    const ls = uzStorage();
    if (ls) {
        ls.setItem(key, v);
    }
};
// 删除一个缓存
const rmStorage = (key) => {
    const ls = uzStorage();
    if (ls && key) {
        ls.removeItem(key);
    }
};
// 获取一个缓存
const getlocalStorage = (key) => {
    const ls = uzStorage();
    if (ls) {
        let v = ls.getItem(key);
        if (!v) {
            return;
        }
        if (v.indexOf("obj-") === 0) {
            v = v.slice(4);
            return JSON.parse(v);
        }
        if (v.indexOf("str-") === 0) {
            return v.slice(4);
        }
    }
};
export {
    rmStorage,
    setStorage,
    getlocalStorage
};
